@import '../../../colors.scss';

.view-resource-details-button {
  border-radius: 27px !important;
  color: white;
  margin-right: 5px;
  font-size: $buttonFontSize;

  i {
    font-size: 1rem;
  }
}

.resource-details-modal {
  .share-button {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
  }
  .modal-body {
    color: grey;
  }
}
