@import '../../../colors.scss';

.visit-link-button {
  border-radius: 27px !important;
  color: white !important;
  font-size: $buttonFontSize;
  margin-right: 0.5rem;

  i {
    font-size: 0.9rem;
  }
}
