@import '../../colors.scss';

.widget-container {
  border: 1px solid grey;
  border-radius: 27px;
  padding: 0.5rem;
  margin-bottom: 1rem;

  h4 {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $primary;
    color: white;
    font-size: 1rem;
    margin: -0.8rem -0.5rem 2rem -0.5rem;
    border-radius: 27px;
    padding: 0.3rem;
    text-align: center;
    text-transform: uppercase;
    min-height: 50px;
  }
  li.ais-Hits-item {
    margin-top: 0px;
    padding-top: 0px;

    .hit-description {
      .ais-Highlight-nonHighlighted {
        font-size: 0.8rem !important;
      }
    }
  }
}
