@import '../../colors.scss';

.nav.nav-tabs {
  display: flex;
  justify-content: space-around;
  .nav-link {
    &:hover {
      border: none;
      color: $primary;
    }
  }
  .nav-item {
    width: 50%;
    text-align: center;

    .active {
      border: none;
      border-bottom: 2px solid $primary;
    }
  }
}
.tab-content {
  .row {
    padding-top: 1rem;
  }
}

.MineItems {
  &__resource-container:first-child {
    margin-top: 1.5rem;
  }

  &__resource-container {
    padding-bottom: 1rem;
    margin-bottom: 1rem;

    h4 {
      font-size: 1.2rem;
    }

    p {
      margin-bottom: 0.5rem;
      color: gray;
    }
  }

  &__footer {
    padding: 1rem 0rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
