$primary: #038fa1;
$shareButtonBackground: #71c174;
$info: #71c174;
// $secondary: #71c174;
$grey: rgb(51, 51, 51);

$small: 768px;
$medium: 900px;

$custButtonPadding: 0.5rem 0.8rem;

$buttonFontSize: 0.8rem;
