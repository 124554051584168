@import '../../colors.scss';

.icon-desc-container {
  display: flex;
  margin-bottom: 1rem;
  a {
    max-width: 90%;
    word-wrap: break-word;
  }

  i {
    color: $primary;
    font-size: 1.5rem;
    margin-right: 0.5rem;
  }
}

.hit-footer-like-and-date {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0rem;
}
