@import '../../colors.scss';

.result-column {
  margin-bottom: 1rem;
  padding: 0rem 2rem !important;
  @media screen and (max-width: $small) {
    padding: 0rem 1rem !important;
  }
}

.ais-Hits-item {
  width: 100% !important;
  border: none;
  box-shadow: none;

  .hit-content {
    width: 100%;
  }

  .hit-name-button {
    padding-left: 0px;
    padding-bottom: 0px;
    text-align: left;
  }

  .hit-name {
    .ais-Highlight-nonHighlighted,
    .ais-Highlight em {
      font-size: 1.2rem;
      font-style: normal;
      font-weight: 600;
      color: black;
    }
  }

  .hit-description {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    .ais-Highlight-nonHighlighted,
    .ais-Highlight em {
      font-size: 1rem;
      color: grey;
      text-transform: none;
      font-style: normal;
    }
  }

  .pre-footer {
    font-weight: 600;
    margin-bottom: 1rem;
    color: black;
  }
}

// footer
.hit-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.info {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;

  div {
    background-color: #e8e8e8;
    padding: 0rem 1.5rem;
    border-radius: 100px;
    max-width: 100%;
    div {
      background-color: #d4d4d4;
      padding: 0rem 1.5rem;
      border-radius: 100px;
      div {
        background-color: #c0c0c0;
        padding: 0rem 1.5rem;
        border-radius: 100px;
        div {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #acacaf;
          padding: 0rem 1.5rem;
          border-radius: 100px;
          select {
            background-color: $primary;
            color: white;
            text-transform: uppercase;
            border-radius: 100px;
            padding-left: 3.5rem;
          }
        }
      }
    }
  }
}

.media-icon-filter-container {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-inline-start: 0px;
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  border-bottom: 1px gray solid;

  li {
    width: 12%;
    width: 12%;
    height: 6rem;
    text-align: center;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-transform: uppercase;
      font-family: 'Oswald', sans-serif;
      color: black;
    }

    img {
      display: block;
      width: 4rem;
      height: 4rem;
      margin-bottom: 1rem;
    }
  }
}

.ais-Menu-showMore {
  background-color: white;
  color: black
}
.ais-Menu-showMore:hover {
  background-color: white;
  color: black
}
.ais-Menu-showMore:focus {
  background-color: white;
  color: black
}

.search-facets {
  h5 {
    border: 2px solid $primary;
    padding: 0.5rem;
    border-radius: 27px;
    color: $primary;
    text-transform: uppercase;
    font-weight: normal;
    margin-top: 1rem;
    font-size: 1rem;
  }

  li a {
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;
    color: $grey;

    .ais-Menu-count,
    .ais-HierarchicalMenu-count {
      background-color: inherit;
      color: grey;
    }
  }
  li label {
    width: 100%;
    display: flex;
    align-items: center;

    input {
      margin-right: 5px;
    }

    .ais-RefinementList-count {
      position: absolute;
      right: 10px;
      background-color: inherit;
      color: grey;
    }
  }

  .ais-RefinementList-showMore,
  .ais-HierarchicalMenu-showMore {
    background-color: white;
    color: black;
  }
  .ais-RefinementList-showMore,
  .ais-HierarchicalMenu-showMore:hover {
    background-color: white;
    color: black;
  }

  .ais-ClearRefinements-button {
    margin-top: 1rem;
    background-color: $primary;
    width: 100%;
    padding: 0.5rem;
    border-radius: 27px;
  }
}

// pagination

.ais-Pagination-list {
  margin-top: 1rem;

  .ais-Pagination-link {
    color: $primary;
  }

  .ais-Pagination-link--selected {
    background-color: $primary;
    color: white;
  }
}
