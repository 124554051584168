.like-button {
  background-color: inherit;
  border: 1px;

  .like-button-inner-container {
    display: flex;
    float: left;
    position: relative;
    align-items: center;
    justify-content: center;

    i {
      position: absolute;
      font-size: 10px;
      color: white;
    }
  }
  span {
    display: inline-block;
    margin-left: 4px;
  }
}
