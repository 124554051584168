@import '../../colors.scss';

.custom-auth {
  [class^='Button__button'] {
    background-color: $primary;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 27px;
    color: white;
  }
  [class^='Anchor'] {
    color: $primary;
  }
  [class^='Section__sectionFooter__'] {
    display: flex;
    flex-flow: row-reverse;
    align-items: center;
    justify-content: space-between;
    margin-top: 2rem;
  }
  [class^='Input__input_'] {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin-bottom: 1rem;
  }
  [class^='Form__formContainer'] {
    min-width: 400px;
    @media screen and (max-width: $small) {
      min-width: 100%;
    }
  }
}
