@import './colors.scss';

$small: 768px;
$medium: 900px;
$large: 1200px;

// this to override amplify UI
:root {
  --amplify-primary-color: #038fa1;
  --amplify-primary-tint: #038fa1;
  --amplify-primary-shade: #038fa1;
}

// navbar
.navbar-container {
  background-color: #e8e8e8 !important;
  position: sticky;
  top: 0; /* Don't forget this, required for the stickiness */
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
  // margin-bottom: 2rem;
  z-index: 1000;

  .navbar-brand {
    margin-right: 4rem;
  }

  @media screen and (max-width: $small) {
    padding-bottom: 0.5rem;
  }

  .menu-item-divider {
    height: 1.2rem;
    border-right: 1px solid gray;
    @media screen and (max-width: $small) {
      display: none;
    }
  }

  .login-menu-item {
    border: 2px solid $primary;
    padding: 3px;
    border-radius: 20px;
    background-color: white;
    padding-left: 10px !important;
    padding-right: 10px !important;

    i {
      margin-right: 4px;
      color: $primary;
    }
  }
}
.media-oasis-brand {
  min-width: 125px;
}

.searchbox {
  flex-grow: 1;

  @media screen and (max-width: $small) {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    width: 100%;
  }

  form {
    max-width: 400px;
    @media screen and (max-width: $small) {
      max-width: 100%;
    }
  }
}

.ais-SearchBox-input {
  border-color: $primary;
  border-width: 2px;
  border-radius: 27px;
  min-width: 100%;

  &:focus {
    outline: inherit;
  }
}

// forms
.form-error {
  color: #dc3545;
  max-width: 374px;
}

// search
.media-icon-filter {
  @media screen and (max-width: $small) {
    display: none;
  }
  @media screen and (max-width: $large) {
    display: none;
  }
}

// common
.header-on-prime-bkgrnd {
  background-color: $primary;
  color: white;
  padding: 0.5rem;
  border-radius: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}

.header-with-primary-outline {
  border: 2px solid $primary;
  padding: 0.5rem;
  border-radius: 27px;
}

.carousel-indicators {
  li {
    width: 1rem !important;
    height: 1rem !important;
    border-radius: 50px !important;
    border-top: 0px !important;
    border-bottom: 0px !important;
    background-color: #71c174 !important;
  }
  .active {
    background-color: $primary !important;
  }
}
