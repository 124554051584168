@import '../../../colors.scss';

.share-button {
  // background-color: $shareButtonBackground !important;
  // border-color: $shareButtonBackground !important;
  border-radius: 27px !important;
  font-size: $buttonFontSize;
}

.share-button-icon {
  margin-right: 0.5rem;
}

.share-copy-container {
  display: flex;
  align-items: center;
  border: 1px gray solid;
  padding: 0rem 0.5rem;
  margin: 1rem 0rem;

  .share-copy-url {
    display: inline-block;
    max-width: 85%;
    word-wrap: break-word;
  }
}
